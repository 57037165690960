<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo motorista</span>
    </button>
    <b-table
      :data="allDrivers"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="
        (row) =>
          $buefy.toast.open(
            `Visualizando os detalhes do ${row.name.toUpperCase()} ${row.last_name.toUpperCase()}`
          )
      "
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{
          props.row.name.toUpperCase()
        }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{
            props.row.name.toUpperCase()
          }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="last_name"
        label="Sobrenome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.last_name }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{
            props.row.last_name.toUpperCase()
          }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="nickname"
        label="Apelido"
        :searchable="true"
        sortable
        v-slot="props"
        >{{
          props.row.nickname ? props.row.nickname.toUpperCase() : 'N/A'
        }}</b-table-column
      >

      <b-table-column
        field="driver_type"
        label="Tipo"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.driver_type.toUpperCase() }}</b-table-column
      >

      <b-table-column
        field="infractions"
        label="Qtd. Multas"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.infractions.length }}</b-table-column
      >

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Multas:</strong>
                <small>
                  <span
                    v-for="(item, index) of props.row.infractions"
                    :key="index"
                  >
                  <br/>
                    - {{ $moment(item.date).format('DD/MM/YYYY') }} ({{ item.type }})
                  </span>
                </small>
                <br />
                <br />
                <strong
                  >{{ props.row.name.toUpperCase() }}
                  {{ props.row.last_name.toUpperCase() }}</strong
                >
                <br />
                <strong>Tipo de cobrança:</strong>
                <small>{{
                  props.row.charge_type ? props.row.charge_type : 'N/A'
                }}</small>
                <br />
                <strong>Valor de cobrança:</strong>
                <small>{{
                  props.row.charge_value ? props.row.charge_value : 'N/A'
                }}</small>
                <br />
                <strong>CPF:</strong>
                <small>{{ props.row.cpf }}</small>
                <br />
                <strong>RG:</strong>
                <small>{{ props.row.rg }}</small>
                <br />
                <strong>CNH:</strong>
                <small>{{ props.row.cnh }}</small>
                <br />
                <strong>Tipo de motorista:</strong>
                <small>{{ props.row.driver_type.toUpperCase() }}</small>
                <br />
                <strong>Banco:</strong>
                <small>{{ props.row.bank }}</small>
                <br />
                <strong>Agencia:</strong>
                <small>{{ props.row.bank_agency }}</small>
                <br />
                <strong>Conta:</strong>
                <small>{{ props.row.bank_account }}</small>
                <br />
                <strong>Endereço:</strong>
                <small>{{ props.row.bank_account }}</small>
                <br />
                <strong>Número:</strong>
                <small>{{ props.row.bank_account }}</small>
                <br />
                <strong>Bairro:</strong>
                <small>{{ props.row.district.toUpperCase() }}</small>
                <br />
                <strong>Cidade:</strong>
                <small>{{ props.row.city.toUpperCase() }}</small>
                <br />
                <strong>Estado:</strong>
                <small>{{ props.row.state.toUpperCase() }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="Sobrenome *">
              <b-input required v-model="model.last_name"></b-input>
            </b-field>
            <b-field label="Apelido *">
              <b-input required v-model="model.nickname"></b-input>
            </b-field>
            <b-field label="E-mail *">
              <b-input type="email" required v-model="model.email"></b-input>
            </b-field>
            <b-field label="Senha">
              <b-input
                type="password"
                required
                v-model="model.password"
              ></b-input>
            </b-field>
            <b-field label="Telefone *">
              <b-input
                type="number"
                required
                v-model="model.phone_number"
              ></b-input>
            </b-field>
            <b-field label="CPF *">
              <b-input type="number" required v-model="model.cpf"></b-input>
            </b-field>
            <b-field label="RG *">
              <b-input type="number" required v-model="model.rg"></b-input>
            </b-field>
            <b-field label="Data de nascimento *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.birth_date"
                placeholder="Selecione uma data"
              ></b-datepicker>
              <b-button
                @click="$refs.datepicker.toggle()"
                icon-left="calendar-today"
                type="is-primary"
              />
            </b-field>
            <b-field label="Data de expedição *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.expedition_date"
                placeholder="Selecione uma data"
              ></b-datepicker>
              <b-button
                @click="$refs.datepicker.toggle()"
                icon-left="calendar-today"
                type="is-primary"
              />
            </b-field>
            <b-field label="CNH *">
              <b-input type="number" required v-model="model.cnh"></b-input>
            </b-field>
            <b-field label="Validade da CNH *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.expiry_cnh"
                placeholder="Selecione uma data"
              ></b-datepicker>
              <b-button
                @click="$refs.datepicker.toggle()"
                icon-left="calendar-today"
                type="is-primary"
              />
            </b-field>
            <b-field label="Tipo de motorista *">
              <b-select
                placeholder="Selecione o tipo"
                required
                expanded
                v-model="model.driver_type"
              >
                <option
                  v-for="(item, index) in ['Parceiro', 'Funcionário']"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Tipo de cobrança *">
              <b-select
                placeholder="Selecione o tipo"
                required
                expanded
                v-model="model.charge_type"
              >
                <option
                  v-for="(item, index) in ['Fixo', '%']"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Valor da cobrança *">
              <b-input required v-model="model.charge_value"></b-input>
            </b-field>
            <b-field label="Status *">
              <b-select
                placeholder="Selecione o tipo"
                required
                expanded
                v-model="model.status"
              >
                <option
                  v-for="(item, index) in ['Em serviço', 'Online', 'Offline']"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Banco *">
              <b-input type="number" required v-model="model.bank"></b-input>
            </b-field>
            <b-field label="Agência *">
              <b-input
                type="number"
                required
                v-model="model.bank_agency"
              ></b-input>
            </b-field>
            <b-field label="Conta *">
              <b-input
                type="number"
                required
                v-model="model.bank_account"
              ></b-input>
            </b-field>
            <b-field label="Endereço *">
              <b-input required v-model="model.street"></b-input>
            </b-field>
            <b-field label="Número *">
              <b-input required v-model="model.number"></b-input>
            </b-field>
            <b-field label="CEP *">
              <b-input
                type="number"
                required
                v-model="model.postal_code"
              ></b-input>
            </b-field>
            <b-field label="Bairro *">
              <b-input required v-model="model.district"></b-input>
            </b-field>
            <b-field label="Complemento">
              <b-input v-model="model.complement"></b-input>
            </b-field>
            <b-field label="Estado *">
              <b-select
                @input="statesCities"
                placeholder="Selecione um estado"
                required
                expanded
                v-model="model.state"
              >
                <option
                  v-for="(item, index) in states"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Cidade *">
              <b-select
                placeholder="Selecione uma cidade"
                required
                expanded
                v-model="model.city"
              >
                <option
                  v-for="(item, index) in cities"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EventBus } from '../event-bus.js'
import service from '../features/drivers/store/service'
import Driver from '../models/driver'
import StatesCities from '../assets/data/estados-cidades.json'

export default {
  name: 'Drivers',
  data() {
    return {
      model: Driver.model,
      showDetailIcon: false,
      isCardModalActive: false,
      cities: [],
      states: [],
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('drivers', ['allDrivers']),
  },
  methods: {
    ...mapActions('drivers', ['getAllDrivers']),
    async init() {
      await this.getAllDrivers()

      console.log('drivers ', this.allDrivers)

      this.states = StatesCities.states.map((x) => {
        return { id: x.initials, name: x.name }
      })

      this.cities = StatesCities.states[0].cities
      this.model.city = StatesCities.states[0].cities[0]
      this.model.state = this.states[0].id
    },
    statesCities() {
      this.cities = StatesCities.states.find(
        (x) => x.initials === this.model.state
      ).cities
    },
    onEdit(item) {
      item.expiry_cnh = new Date(this.$moment(item.expiry_cnh))
      item.expedition_date = new Date(this.$moment(item.expedition_date))
      item.birth_date = new Date(this.$moment(item.birth_date))

      this.model = { ...item }
    },
    async validation() {
      let isValid = true

      await Driver.required.forEach((x) => {
        if (!this.model[x]) {
          isValid = false
        }
      })

      return isValid
    },
    async save() {
      const driver = { ...this.model }

      if (!(await this.validation()))
        return this.$error('É necessário preencher todos os campos com *')

      driver.expedition_date = this.$moment(driver.expedition_date).format(
        'YYYY-MM-DD'
      )
      driver.expiry_cnh = this.$moment(driver.expiry_cnh).format('YYYY-MM-DD')
      driver.birth_date = this.$moment(driver.birth_date).format('YYYY-MM-DD')

      try {
        const save = driver.id
          ? await service.updateDriver(driver)
          : await service.saveDriver(driver)

        await this.$success('Motorista')
        await location.reload(true)

        this.model = Driver.model
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o motorista?')

        if (result.isConfirmed) {
          await service.deleteDriver(id)

          await this.$delete('Motorista')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
